import * as types from "../types/digitalDocuments";

export const requestAllDigitalDocuments = (data) => {
  return {
    type: types.GET_ALL_DIGITAL_DOCUMENTS_REQUEST,
    payload: data,
  };
};

export const receiveAllDigitalDocuments = (data) => {
  return {
    type: types.GET_ALL_DIGITAL_DOCUMENTS_RECEIVE,
    payload: data,
  };
};

export const getDigitalDocumentPlaceholdersRequest = () => ({
  type: types.GET_DIGITAL_DOCUMENTS_PLACEHOLDERS_REQUEST,
});
export const getDigitalDocumentPlaceholdersReceive = (payload) => ({
  type: types.GET_DIGITAL_DOCUMENTS_PLACEHOLDERS_RECEIVE,
  payload,
});

export const saveDigitalDocumentRequest = (payload) => ({
  type: types.SAVE_DIGITAL_DOCUMENT_REQUEST,
  payload,
});

export const getDigitalDocument = (payload) => ({
  type: types.GET_DIGITAL_DOCUMENT_REQUEST,
  payload,
});

export const receiveDigitalDocumentRequest = (payload) => ({
  type: types.GET_DIGITAL_DOCUMENT_RECEIVE,
  payload,
});

export const requestDeleteDigitalDocument = (payload) => {
  return {
    type: types.DELETE_DIGITAL_DOCUMENT_REQUEST,
    payload,
  };
};

export const receiveDeleteDigitalDocument = (data) => {
  return {
    type: types.DELETE_DIGITAL_DOCUMENT_RECEIVE,
    payload: data,
  };
};

export const getSpecificDigitalDocumentRequest = (payload) => ({
  type: types.GET_SPECIFIC_DIGITAL_DOCUMENT_REQUEST,
  payload
});

export const getSpecificDigitalDocumentReceive = (payload) => ({
  type: types.GET_SPECIFIC_DIGITAL_DOCUMENT_RECEIVE,
  payload,
});


export const requestSendDigitalDocument = (payload) => {
  return {
    type: types.SEND_DIGITAL_DOCUMENT_REQUEST,
    payload,
  };
};

export const receiveSendDigitalDocument = (data) => {
  return {
    type: types.SEND_DIGITAL_DOCUMENT_RECEIVE,
    payload: data,
  };
};