import React from "react";
import Auth from "../../utils/Auth";
import { Route, Redirect } from "react-router-dom";
import MainLayoutComponent from "../../containers/dashboard/MainLayout/MainLayout";
import { isUserHasPermission, isSuperAdmin } from "../../utils/Permissions";
import History from "../../routes/History";

export const MainLayout = (component) => {
  return <MainLayoutComponent>{component}</MainLayoutComponent>;
};

export const NoLayout = (component) => {
  return <>{component}</>;
};

export const PrivateRoute = ({
  component: Component,
  permission,
  path,
  ...rest
}) => {
  const isAuthenticated = localStorage.getItem("token") ? true : false;


  !isAuthenticated &&
    localStorage.setItem("prevPath", History.location.pathname + History.location.search);


  const permissionArr =
    permission && typeof permission !== "boolean"
      ? permission.map((elt) => isUserHasPermission(elt))
      : [];

      
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated
          ? permissionArr.some((elt) => elt === true) ||
            isSuperAdmin() ||
            permission === true
            ? path.split("/")[1] === "resetPassword" ||
              path.split("/")[1] === "forgetPassword"
              ? MainLayout(<Redirect to="/" />)
              : MainLayout(<Component {...props} />)
            : MainLayout(<Redirect to="/" />)
          : path.split("/")[1] === "resetPassword" ||
            path.split("/")[1] === "forgetPassword"
          ? NoLayout(<Component {...props} />)
          : NoLayout(<Redirect to={{ pathname: "/redirect-login" }} />)
      }
    />
  );
};
