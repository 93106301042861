import React, { Component } from "react";
import { Router } from "react-router-dom";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import history from "../routes/History";
import Routes from "../routes/Routes";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import messages from "../assets/Local/messages";
import "./App.scss";
import { Loader } from "components/Loader/Loader";
import { SnackbarComponent } from "components/Snackbar/Snackbar";
import { Helmet } from "react-helmet";
import { getURLParams } from "../utils/shared";
import ReactGA from "react-ga";
import ScrollToTop from "react-scroll-to-top";
import { realTimeDbUnreadNotifications } from "../firebase/utils";
import { setCurrentLang } from "../store/actions/Lang";
import { popupNotificationRequest } from "../store/actions/notification";
import { companyPortalSetupRequest } from "../store/actions/index";
import { getDomain } from "utils/redirections";
// ========== General styles ==========
class App extends Component {
  constructor() {
    super();
    this.state = {
      pathname: "/",
    };
  }

  componentDidMount() {
    this.props.companyPortalSetupRequest();

    const locale = getURLParams("locale");
    console.log("build", process.env);
    if (locale && (locale === "ar" || locale === "en"))
      this.props.setCurrentLang(locale);

    const trackingId = process.env.REACT_APP_COMPANY_GOOGLE_ANALYTICS_KEY;
    localStorage.getItem("lang")
      ? localStorage.setItem("lang", localStorage.getItem("lang"))
      : localStorage.setItem("lang", this.props.lang);
    ReactGA.initialize(trackingId);
    const pathRoute = history.location.pathname.split("/")[1];
    const pageName = pathRoute === "" ? "dashboard" : pathRoute;
    history.location.pathname !== "/setup" &&
      ReactGA.pageview(
        `${localStorage.getItem("domain") + "/" + pathRoute}`,
        [],
        pageName
      );
    if (localStorage.getItem("token")) {
      this.props.popupNotificationRequest();
      const decodedToken = jwt_decode(localStorage.getItem("token"));
      realTimeDbUnreadNotifications(decodedToken.sub);
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "yzspq3ie",
      });
      window.Intercom("update");
    }

    window.Intercom("boot", {
      api_base: "https://api-iam.intercom.io",
      app_id: "yzspq3ie",
    });
    window.Intercom("update");
  }

  componentDidUpdate() {
    if (
      history.location.pathname !== this.state.pathname &&
      history.location.pathname !== "/setup"
    ) {
      const pathRoute = history.location.pathname.split("/")[1];
      const pageName = pathRoute === "" ? "dashboard" : pathRoute;
      this.setState({ pathname: history.location.pathname });
      ReactGA.set({
        page: localStorage.getItem("domain") + "/" + pathRoute,
      });
      ReactGA.pageview(
        `${localStorage.getItem("domain") + "/" + pathRoute}`,
        [],
        pageName
      );
    }
  }

  render() {
    const { lang, loading } = this.props;
    const scrollStyle = {
      left: lang === "ar" ? "40px" : "auto",
      right: lang === "ar" ? "auto" : "40px",
    };
    return (
      <IntlProvider
        locale={lang}
        defaultLocale={lang}
        messages={messages[lang]}
      >
        
        <div
          className={lang === "ar" ? "rtl" : "ltr"}
          dir={lang === "ar" ? "rtl" : "ltr"}
        >
          <Helmet>
            <title>{getDomain()}</title>
          </Helmet>
          {loading ? <Loader /> : null}
          <Router history={history}>
            <SnackbarComponent />
            <ScrollToTop smooth top={300} style={scrollStyle} />
            {Routes}
          </Router>
        </div>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.locale.lang,
    loading: state.loading,
    token: state.login.userToken,
  };
};
const mapDispatchToProps = {
  setCurrentLang,
  popupNotificationRequest,
  companyPortalSetupRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
