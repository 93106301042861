import { axiosInstance } from "./config";

let handlerEnabled = true;

const getRemindersRequest = async () => {
  return await axiosInstance.get(
    `/candidate_job_request/GetNewComersAnnouncement`,
    {
      handlerEnabled,
    }
  );
};
const setRemindersRequest = async (data) => {
  console.log(data);
  return await axiosInstance.put(
    `/candidate_job_request/AddNewComersAnnouncement`,
    data,
    {
      handlerEnabled,
    }
  );
};

export default {
  getRemindersRequest,
  setRemindersRequest,
};
