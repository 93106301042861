import * as types from '../types/requests';

export const getCandidateOfferTemplateRequest = (payload) => ({
  type: types.REQUEST_CANDIDATE_OFFER_TEMPLATE,
  payload,
});
export const getCandidateOfferTemplateRecieve = (payload) => ({
  type: types.RECIEVE_CANDIDATE_OFFER_TEMPLATE,
  payload,
});
export const requestsRequest = (payload) => ({
  type: types.REQUESTS_REQUEST,
  payload,
});
export const requestsReceive = (payload) => ({
  type: types.REQUESTS_RECIEVE,
  payload,
});
export const oldRequestsRequest = (payload) => ({
  type: types.OLD_REQUESTS_REQUEST,
  payload,
});
export const oldRequestsReceive = (payload) => ({
  type: types.OLD_REQUESTS_RECIEVE,
  payload,
});

export const approveRequestsRequest = (payload) => ({
  type: types.APPROVE_REQUESTS_REQUEST,
  payload,
});
export const approveRequestsReceive = () => ({
  type: types.APPROVE_REQUESTS_RECIEVE,
});

export const reAssignAgentsRequest = (payload) => ({
  type: types.REASSIGN_REQUESTS_REQUEST,
  payload,
});
export const reAssignAgentsReceive = () => ({
  type: types.REASSIGN_REQUESTS_RECIEVE,
});

export const candidateApprovalsRequest = (payload) => ({
  type: types.CANDIDATE_REQUESTS_REQUEST,
  payload,
});
export const candidateApprovalsReceive = (payload) => ({
  type: types.CANDIDATE_REQUESTS_RECIEVE,
  payload,
});

export const approveCandidateRequest = (payload) => ({
  type: types.APPROVE_CANDIDATE_REQUESTS_REQUEST,
  payload,
});






export const acceptedOffersReceive = (payload) => ({
  type: types.ACCEPTED_OFFERS_RECIEVE,
  payload,
});

export const acceptedOffersRequest = (payload) => ({
  type: types.ACCEPTED_OFFERS_REQUEST,
  payload,
});






export const approveCandidateReceive = (payload) => ({
  type: types.APPROVE_CANDIDATE_REQUESTS_RECIEVE,
  payload,
});

export const disapproveCandidateRequest = (payload) => ({
  type: types.REJECT_CANDIDATE_REQUESTS_REQUEST,
  payload,
});

export const disApproveRequestsRequest = (payload) => ({
  type: types.DISAPPROVE_REQUESTS_REQUEST,
  payload,
});
export const disApproveRequestsReceive = () => ({
  type: types.DISAPPROVE_REQUESTS_RECIEVE,
});

export const getOfferTemplateRequest = (payload) => ({
  type: types.GET_OFFER_TEMPLATE_REQUEST,
  payload,
});
export const getOfferTemplateReceive = (payload) => ({
  type: types.GET_OFFER_TEMPLATE_RECEIVE,
  payload,
});

export const saveSignedOfferTemplateRequest = (payload) => ({
  type: types.SAVE_OFFER_TEMPLATE_REQUEST,
  payload,
});
export const saveSignedOfferTemplateReceive = (payload) => ({
  type: types.SAVE_OFFER_TEMPLATE_RECEIVE,
  payload,
});

export const saveSignatureRequest = (payload) => ({
  type: types.SAVE_OFFER_SIGNATURE_REQUEST,
  payload,
});
export const saveSignatureReceive = (payload) => ({
  type: types.SAVE_OFFER_SIGNATURE_RECEIVE,
  payload,
});
export const ActiveUnifunicSMSRequest = (data) => {
  return {
    type: types.GET_ACTIVE_UNIFUNIC,
    payload: data,
  };
};
export const sendUnifunicSMSRequest = (data) => {
  return {
    type: types.SEND_UNIFUNIC_SMS,
    payload: data,
  };
};

export const recieveIsActiveUnifonic = (data) => {
  return {
    type: types.GET_ACTIVE_UNIFUNIC_RECIEVE,
    payload: data,
  };
};
export const saveUploadOffer = (data) => {
  return {
    type: types.SAVE_UPLOADED_APPROVAL_OFFER,
    payload: data,
  };
};

/* CONTRACT */
export const saveUploadContract = (data) => {
  return {
    type: types.SAVE_UPLOADED_APPROVAL_CONTRACT,
    payload: data,
  };
};
export const saveSignedContractTemplateRequest = (payload) => ({
  type: types.SAVE_CONTRACT_TEMPLATE_REQUEST,
  payload,
});
export const saveSignedContractTemplateReceive = (payload) => ({
  type: types.SAVE_CONTRACT_TEMPLATE_RECIEVE,
  payload,
});
export const getContractTemplateRequest = (payload) => ({
  type: types.GET_CONTRACT_TEMPLATE_REQUEST,
  payload,
});
export const getContractTemplateReceive = (payload) => ({
  type: types.GET_CONTRACT_TEMPLATE_RECIEVE,
  payload,
});