import * as types from "../types/reminders";

export const getRemindersRequest = (x) => {

  return {
    type: types.GET_AGENT_REMINDERS_REQUEST,
  };
};

export const getRemindersReceive = (payload) => {
  return {
    type: types.GET_AGENT_REMINDERS_RECEIVE,
    payload,
  };
};

export const sendRemindersRequest = (payload) => {
  console.log(payload);
  return {
    type: types.SEND_AGENT_REMINDERS_REQUEST,
    payload,
  };
};

export const receiveSendRemindersRequests = (payload) => {
  return {
    type: types.SEND_AGENT_REMINDERS_RECEIVE,
    payload,
  };
};
