import * as types from "../types/createNewRequest";

const INITIAL_STATE = {
  step1: {},
  step2: {},
  step3: {},
  jobRequisitionSetup: [],
  jobRequisitionValidation: false,
  questionsLibrary: [],
  summaryDetails: {},
  jobPostURL: "",
  remainingJobs: 0,
  allTemplates: [],
  singleTemplateData: {},
  advertisementPreview: {},
  publishAdvertisementLink: "",
  isScreeningValid: false,
  isEditMode: false,
  hasApprovalSetup: false,
  newJobTitleId: null,
  disableScreeningQuestions: null,
  approvalStage: 1,
  approvalStatus: null,
  drJopPosted: false,
  // IsShared: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_JOB_DETAILS_RECEIVE:
      return { ...state, step1: action.payload };
    case types.MANAGE_JOB_REQUISITION_VALIDATION_RECEIVE:
      return { ...state, jobRequisitionValidation: action.payload };
    case types.MANAGE_JOB_REQUISITION_RECEIVE:
      return { ...state, step2: action.payload };
    case types.RECEIVE_JOB_REQUEST_JOB_REQUESITION:
      return { ...state, jobRequisitionSetup: action.payload };
    case types.POST_MANAGE_SCREENING_QUESTIONS_RECEIVE:
      return { ...state, step3: { ...action.payload } };
    case types.GET_LIBRARY_QUESTIONS_RECEIVE:
      return { ...state, questionsLibrary: [...action.payload] };
    case types.RECEIVE_SUMMARY_DETAILS:
      return { ...state, summaryDetails: action.payload };
    case types.RECEIVE_PUBLISH_JOB_POST:
      return {
        ...state,
        jobPostURL: action.payload.url,
        remainingJobs: action.payload.remaining_job,
      };
    case types.JOB_REQUISITION_COPY_ALL_TEMPLATE_RECEIVE:
    case types.SCREENING_COPY_ALL_TEMPLATE_RECEIVE:
      return { ...state, allTemplates: action.payload };
    case types.JOB_REQUISITION_COPY_SINGLE_TEMPLATE_RECEIVE:
    case types.SCREENING_COPY_SINGLE_TEMPLATE_RECEIVE:
      return { ...state, singleTemplateData: action.payload };
    case types.RECEIVE_ADVERTISEMENT_PREVIEW:
      return { ...state, advertisementPreview: action.payload };
    case types.RECEIVE_PUBLISH_ADVERTISEMENT:
      return { ...state, publishAdvertisementLink: action.payload.url };
    case types.POST_MANAGE_SCREENING_QUESTIONS_VALIDATE_RECEIVE:
      return { ...state, isScreeningValid: action.payload };
    case types.IS_JOB_REQUEST_EDIT_MODE:
      return { ...state, isEditMode: action.payload };
    case types.RECEIVE_HAS_APPROVAL_SETUP:
      return { ...state, hasApprovalSetup: action.payload };
    case types.ADD_NEW_JOB_TITLE_RECEIVE:
      return { ...state, newJobTitleId: action.payload };
    case types.DISABLE_SCREENING_QUESTIONS_RECEIVE:
      return { ...state, disableScreeningQuestions: action.payload };
    case types.RECEIVE_DR_JOB_POST:
      return { ...state, drJopPosted: action.payload };
    case types.RECEIVE_SHARE_JOB:
      console.log(action);
      return ''
      // return { ...state, IsShared: action.payload };
    case types.GET_JOB_APPROVAL_RECIEVE:
      return {
        ...state,
        approvalStage:
          action.payload?.is_approval_exist &&
          action.payload?.job_approval_stageId
            ? action.payload?.job_approval_stageId
            : null,
        approvalStatus:
          action.payload?.is_approval_exist &&
          action.payload?.job_approval_status
            ? action.payload?.job_approval_status
            : null,
      };
    default:
      return state;
  }
};
