import * as types from "../types/digitalDocuments";

const INITIAL_STATE = {
  list: {},
  placeholders: [],
  document: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ALL_DIGITAL_DOCUMENTS_RECEIVE:
      return { ...state, list: { ...action.payload } };

    case types.GET_DIGITAL_DOCUMENTS_PLACEHOLDERS_RECEIVE:
      return {
        ...state,
        placeholders: { ...action.payload },
      };
    case types.SAVE_DIGITAL_DOCUMENT_RECEIVE:
      return { ...state, ...action.payload };

    case types.GET_DIGITAL_DOCUMENT_RECEIVE:
      return { ...state, ...action.payload };

    case types.DELETE_DIGITAL_DOCUMENT_RECEIVE:
      return { ...state, ...action.payload };

    case types.GET_SPECIFIC_DIGITAL_DOCUMENT_RECEIVE:
      return { ...state, document: { ...action.payload } };

    case types.SEND_DIGITAL_DOCUMENT_RECEIVE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
