import { call, put } from "redux-saga/effects";
import API from "../../network/apis/competenceSettings";
import * as competenceSettings from "../actions/competenceSettings";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/competenceSettings";
import { dispatchError, showSnackbar } from "utils/shared";

export function* competenceSettingsRequest(  ) {
  try {

    const response = yield call(API.getCompetenceSettings);
  
    yield put(competenceSettings.receiveCompetenceSettings(response.data.data));
  } catch (error) {
    console.log(error)
    dispatchError(error.response?.data);
  }

}

export function* editCompetenceSettings(action) {
  console.log(action)
  try {
    const response = yield call(API.editCompetenceSettings, action.payload);
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}


export function* editCompetenceSettingscategories(action) {
  try {
    const response = yield call(API.editCompetenceSettingscategories, action.payload);
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* editCompetenceSettingsItems(action) {
  try {
    const response = yield call(API.editCompetenceSettingsItems, action.payload);
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* addCompetenceSettingscategories(action) {
  console.log(action)
  try {
    const response = yield call(API.addCompetenceSettingscategories, action.payload);
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}


export function* addCompetenceSettingsItems(action) {
  console.log(action)
  try {
    const response = yield call(API.addCompetenceSettingsItems, action.payload);
    showSnackbar("savedSuccessfuly");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}



export function* competenceSettingsCategoriesRequest(  ) {
  try {

    const response = yield call(API.getCompetenceSettingscategories);
  
    yield put(competenceSettings.receiveGetCompetenceSettingsCategories(response.data.data));
  } catch (error) {
    console.log(error)
    dispatchError(error.response?.data);
  }

}

export function* daleteCompetenceSettingscategories(action) {
  try {
    const response = yield call(API.deleteCompetenceSettingscategories, action.payload);
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* daleteCompetenceSettingsItems(action) {
  try {
    const response = yield call(API.deleteCompetenceSettingsItems, action.payload);
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}


export function* competenceSettingsCategoriesHaveItemsRequest(  ) {
  try {

    const response = yield call(API.getCompetenceSettingscategoriesHaveItems);
  
    yield put(competenceSettings.receiveGetCompetenceSettingsCategoriesHaveItems(response.data.data));
  } catch (error) {
    console.log(error)
    dispatchError(error.response?.data);
  }

}


export function* competenceLookupRequest(  ) {
  try {

    const response = yield call(API.getCompetenceLookup);
  
    yield put(competenceSettings.receiveGetCompetenceLookup(response.data.data));
  } catch (error) {
    console.log(error)
    dispatchError(error.response?.data);
  }

}


export function* CompetenceSettingsSagasWatch() {
  yield takeLatest(type.GET_COMPETENCE_SETTINGS_CATEGORIES_REQUEST, competenceSettingsCategoriesRequest);
  yield takeLatest(type.GET_COMPETENCE_SETTINGS_REQUEST, competenceSettingsRequest);

  yield takeLatest(type.UPDATE_COMPETENCE_SETTINGS_REQUEST, editCompetenceSettings);
  yield takeLatest(type.UPDATE_COMPETENCE_SETTINGS_CATEGORIES_REQUEST, editCompetenceSettingscategories);

  yield takeLatest(type.ADD_COMPETENCE_SETTINGS_CATEGORIES_REQUEST, addCompetenceSettingscategories);
  yield takeLatest(type.DELETE_COMPETENCE_SETTINGS_CATEGORIES_REQUEST, daleteCompetenceSettingscategories);
  yield takeLatest(type.DELETE_COMPETENCE_SETTINGS_ITEMS_REQUEST, daleteCompetenceSettingsItems);
  yield takeLatest(type.ADD_COMPETENCE_SETTINGS_ITEMS_REQUEST, addCompetenceSettingsItems);
  yield takeLatest(type.UPDATE_COMPETENCE_SETTINGS_ITEMS_REQUEST, editCompetenceSettingsItems);

  yield takeLatest(type.GET_COMPETENCE_SETTINGS_CATEGORIES_HAVE_ITEMS_REQUEST, competenceSettingsCategoriesHaveItemsRequest);

  yield takeLatest(type.GET_COMPETENCE_LOOKUP_REQUEST, competenceLookupRequest);


}
