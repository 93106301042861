import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { dispatchError, showSnackbar } from "utils/shared";
import * as type from "../types/reminders";
import * as ACTION from "../actions/reminders";
import API from "../../network/apis/reminders";

export function* getRemindersRequest() {
  try {
    const response = yield call(API.getRemindersRequest);
    yield put(ACTION.getRemindersReceive(response.data.data));
 
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* sendReminderRequest({ payload }) {
  console.log(payload);
  try {
    const response = yield call(API.setRemindersRequest, payload);
    showSnackbar("savedSuccessfuly");
    yield put(ACTION.receiveSendRemindersRequests(response.data.data));
    yield put(ACTION.getRemindersRequest(response.data.data));
  } catch (err) {
    err?.response?.data && dispatchError(err?.response?.data);
  }
}

export function* RemindersSagasWatch() {
  yield takeEvery(type.GET_AGENT_REMINDERS_REQUEST, getRemindersRequest);
  yield takeLatest(type.SEND_AGENT_REMINDERS_REQUEST, sendReminderRequest);
}
