export const GET_ALL_DIGITAL_DOCUMENTS_REQUEST = "GET_ALL_DIGITAL_DOCUMENTS_REQUEST";
export const GET_ALL_DIGITAL_DOCUMENTS_RECEIVE = "GET_ALL_DIGITAL_DOCUMENTS_RECEIVE";


export const GET_DIGITAL_DOCUMENTS_PLACEHOLDERS_REQUEST = "GET_DIGITAL_DOCUMENTS_PLACEHOLDERS_REQUEST";
export const GET_DIGITAL_DOCUMENTS_PLACEHOLDERS_RECEIVE = "GET_DIGITAL_DOCUMENTS_PLACEHOLDERS_RECEIVE";


export const SAVE_DIGITAL_DOCUMENT_REQUEST = "SAVE_DIGITAL_DOCUMENT_REQUEST";
export const SAVE_DIGITAL_DOCUMENT_RECEIVE = "SAVE_DIGITAL_DOCUMENT_RECEIVE";

export const GET_DIGITAL_DOCUMENT_REQUEST = "GET_DIGITAL_DOCUMENT_REQUEST";
export const GET_DIGITAL_DOCUMENT_RECEIVE = "GET_DIGITAL_DOCUMENT_RECEIVE";


export const DELETE_DIGITAL_DOCUMENT_REQUEST = "DELETE_DIGITAL_DOCUMENT_REQUEST";
export const DELETE_DIGITAL_DOCUMENT_RECEIVE = "DELETE_DIGITAL_DOCUMENT_RECEIVE";

export const GET_SPECIFIC_DIGITAL_DOCUMENT_REQUEST = "GET_SPECIFIC_DIGITAL_DOCUMENT_REQUEST";
export const GET_SPECIFIC_DIGITAL_DOCUMENT_RECEIVE = "GET_SPECIFIC_DIGITAL_DOCUMENT_RECEIVE";



export const SEND_DIGITAL_DOCUMENT_REQUEST = "SEND_DIGITAL_DOCUMENT_REQUEST";
export const SEND_DIGITAL_DOCUMENT_RECEIVE = "SEND_DIGITAL_DOCUMENT_RECEIVE";