export const REQUESTS_REQUEST = "REQUESTS_REQUEST";
export const REQUESTS_RECIEVE = "REQUESTS_RECIEVE";

export const OLD_REQUESTS_REQUEST = "OLD_REQUESTS_REQUEST";
export const OLD_REQUESTS_RECIEVE = "OLD_REQUESTS_RECIEVE";

export const APPROVE_REQUESTS_REQUEST = "APPROVE_REQUESTS_REQUEST";
export const APPROVE_REQUESTS_RECIEVE = "APPROVE_REQUESTS_RECIEVE";

export const REASSIGN_REQUESTS_REQUEST = "REASSIGN_REQUESTS_REQUEST";
export const REASSIGN_REQUESTS_RECIEVE = "REASSIGN_REQUESTS_RECIEVE";

export const CANDIDATE_REQUESTS_REQUEST = "CANDIDATE_REQUESTS_REQUEST";
export const CANDIDATE_REQUESTS_RECIEVE = "CANDIDATE_REQUESTS_RECIEVE";

export const APPROVE_CANDIDATE_REQUESTS_REQUEST =
  "APPROVE_CANDIDATE_REQUESTS_REQUEST";
export const APPROVE_CANDIDATE_REQUESTS_RECIEVE =
  "APPROVE_CANDIDATE_REQUESTS_RECIEVE";

export const REJECT_CANDIDATE_REQUESTS_REQUEST =
  "REJECT_CANDIDATE_REQUESTS_REQUEST";
export const REJECT_CANDIDATE_REQUESTS_RECIEVE =
  "REJECT_CANDIDATE_REQUESTS_RECIEVE";

export const DISAPPROVE_REQUESTS_REQUEST = "DISAPPROVE_REQUESTS_REQUEST";
export const DISAPPROVE_REQUESTS_RECIEVE = "DISAPPROVE_REQUESTS_RECIEVE";

export const GET_OFFER_TEMPLATE_REQUEST = "GET_OFFER_TEMPLATE_REQUEST";
export const GET_OFFER_TEMPLATE_RECEIVE = "GET_OFFER_TEMPLATE_RECEIVE";

export const SAVE_OFFER_SIGNATURE_REQUEST = "SAVE_OFFER_SIGNATURE_REQUEST";
export const SAVE_OFFER_SIGNATURE_RECEIVE = "SAVE_OFFER_SIGNATURE_RECEIVE";

export const SAVE_OFFER_TEMPLATE_REQUEST = "SAVE_OFFER_TEMPLATE_REQUEST";
export const SAVE_OFFER_TEMPLATE_RECEIVE = "SAVE_OFFER_TEMPLATE_RECEIVE";

export const GET_ACTIVE_UNIFUNIC = "GET_ACTIVE_UNIFUNIC";
export const SEND_UNIFUNIC_SMS = "SEND_UNIFUNIC_SMS";
export const GET_ACTIVE_UNIFUNIC_RECIEVE = "GET_ACTIVE_UNIFUNIC_RECIEVE";
export const SAVE_UPLOADED_APPROVAL_OFFER = "SAVE_UPLOADED_APPROVAL_OFFER";

/* CONTRACT */
export const SAVE_CONTRACT_TEMPLATE_REQUEST = "SAVE_CONTRACT_TEMPLATE_REQUEST";
export const SAVE_CONTRACT_TEMPLATE_RECIEVE = "SAVE_CONTRACT_TEMPLATE_RECIEVE";
export const SAVE_UPLOADED_APPROVAL_CONTRACT = "SAVE_UPLOADED_APPROVAL_CONTRACT";
export const GET_CONTRACT_TEMPLATE_REQUEST = "GET_CONTRACT_TEMPLATE_REQUEST";
export const GET_CONTRACT_TEMPLATE_RECIEVE = "GET_CONTRACT_TEMPLATE_RECIEVE";
export const REQUEST_CANDIDATE_OFFER_TEMPLATE = "REQUEST_CANDIDATE_OFFER_TEMPLATE";
export const RECIEVE_CANDIDATE_OFFER_TEMPLATE = "RECIEVE_CANDIDATE_OFFER_TEMPLATE";



export const ACCEPTED_OFFERS_RECIEVE="ACCEPTED_OFFERS_RECIEVE"
export const ACCEPTED_OFFERS_REQUEST="ACCEPTED_OFFERS_REQUEST"