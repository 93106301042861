import { call, put } from "redux-saga/effects";
import API from "../../network/apis/ContractTemplate";
import * as ACTIONS from "../actions/ContractTemplate";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/ContractTemplate";
import { dispatchError, showSnackbar } from "utils/shared";
import history from "../../routes/History";


export function* getContractTemplateSettings() {
  try {
    const response = yield call(API.getContractTemplateSettings);
    yield put(ACTIONS.getContractTemplateSettingsReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getContractTemplateDefaultSettings() {
  try {
    const response = yield call(API.getContractTemplateDefaultSettings);
    yield put(ACTIONS.getContractTemplateDefaultSettingsReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getContractTemplatePlaceholders() {
  try {
    const response = yield call(API.getContractTemplatePlaceholders);
    yield put(ACTIONS.getContractTemplatePlaceholdersReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* saveContractTemplateSettings({ payload }) {
  try {
    yield call(API.saveContractTemplateSettings, payload);
    // yield put(ACTIONS.getContractTemplateSettingsRequest());
    showSnackbar("done");
    history.push("/setup");
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* deleteCoverLetter() {
  try {
    yield call(API.deteleContractTemplateCoverLetter);
    yield put(ACTIONS.getContractTemplateSettingsRequest());
    showSnackbar("done");
  } catch (error) {
    dispatchError(error.response.data);
  }
}

export function* ContractTemplateSetupSagasWatch() {
  yield takeLatest(
    type.GET_CONTRACT_TEMPLATE_SETTINGS_REQUEST,
    getContractTemplateSettings
  );
  yield takeLatest(
    type.GET_CONTRACT_TEMPLATE_DEFAULT_SETTINGS_REQUEST,
    getContractTemplateDefaultSettings
  );
  yield takeLatest(
    type.GET_CONTRACT_TEMPLATE_PLACEHOLDERS_REQUEST,
    getContractTemplatePlaceholders
  );
  yield takeLatest(
    type.SAVE_CONTRACT_TEMPLATE_SETTINGS_REQUEST,
    saveContractTemplateSettings
  );
  yield takeLatest(
    type.DELETE_CONTRACT_TEMPLATE_COVER_LETTER_REQUEST,
    deleteCoverLetter
  );
}