import { axiosInstance } from "./config";

let handlerEnabled = true;

const postJobDetails = async (data) => {
  return await axiosInstance.post(`/admin/job_request/job_details`, data, {
    handlerEnabled,
  });
};
const manageJobRequisition = async (data) => {
  return await axiosInstance.post(`/admin/job_request/job_Requisition`, data, {
    handlerEnabled,
  });
};
const manageJobRequisitionValidation = async (data) => {
  return await axiosInstance.post(
    `/admin/job_request/validate_job_Requisition`,
    data,
    {
      handlerEnabled,
    }
  );
};
const manageJobRequisitionSetup = async () => {
  return await axiosInstance.get(`/admin/job_request/job_Requisition`, {
    handlerEnabled,
  });
};
const manageScreeningQuestions = async (data) => {
  return await axiosInstance.post(`/admin/screening_question/post`, data, {
    handlerEnabled,
  });
};

const validateManageScreeningQuestions = async (data) => {
  return await axiosInstance.post(
    `/admin/screening_question/validate_screenning_question`,
    data,
    {
      handlerEnabled,
    }
  );
};
const requestSummaryDetails = async (jobId) => {
  return await axiosInstance.get(`/admin/job_request/summary/${jobId}`, {
    handlerEnabled,
  });
};
const requestPublishJobPost = async (jobId) => {
  return await axiosInstance.post(`/admin/job_request/publish/${jobId}`, {
    handlerEnabled,
  });
};
const requestJobRequisitionCopyAllTemplate = async () => {
  return await axiosInstance.get(`/admin/job_requisition_template/get`, {
    handlerEnabled,
  });
};
const requestJobRequisitionCopySingleTemplate = async (tempId) => {
  return await axiosInstance.get(
    `/admin/job_requisition_template/get/${tempId}`,
    { handlerEnabled }
  );
};

const getLibraryQuestions = async () => {
  return await axiosInstance.get(`/admin/screening_question_library/get`, {
    handlerEnabled,
  });
};

const requestAdvertisementPreview = async (jobId) => {
  return await axiosInstance.get(`/admin/job_request/announcement/${jobId}`, {
    handlerEnabled,
  });
};
const requestPublishAdvertisement = async ({
  id,
  advertisement_en,
  advertisement_ar,
  is_published,
}) => {
  return await axiosInstance.post(
    `/admin/job_request/publish_announcement/${id}`,
    { advertisement_en, advertisement_ar },
    { params: { isPublished: is_published } }
  );
};

const requestScreeningCopyAllTemplate = async () => {
  return await axiosInstance.get(`/admin/screening_question_template/get`, {
    handlerEnabled,
  });
};
const requestScreeningCopySingleTemplate = async (tempId) => {
  return await axiosInstance.get(
    `/admin/screening_question_template/get/${tempId}`,
    { handlerEnabled }
  );
};

const requestHasApproval = async (data) => {
  return await axiosInstance.get(`/admin/job_request/approval_setup`, {
    handlerEnabled,
    params: {
      ...(data ? { salary_grade_id: data.id } : {}),
    },
  });
};

const requestAddNewJobTitle = async (data) => {
  return await axiosInstance.post(`/admin/job_title/quick_add`, data, {
    handlerEnabled,
  });
};

const requestDisableScreening = async () => {
  return await axiosInstance.get(
    `/admin/subscriptions/disable_screening_questions`,
    {
      handlerEnabled,
    }
  );
};
const getApprovalStageRequest = async () => {
  const job_id = localStorage.getItem("jobOpeningId")
    ? localStorage.getItem("jobOpeningId")
    : "";
  return await axiosInstance.get(
    `/admin/job_request/get_jobApprovalStage?job_id=${job_id}`,
    {
      handlerEnabled,
    }
  );
};

const postDrJob = async () => {
  const job_id = localStorage.getItem("jobOpeningId")
    ? localStorage.getItem("jobOpeningId")
    : "";
  return await axiosInstance.post(
    `/admin/job_request/post_to_dr_job/${job_id}`,
    {
      handlerEnabled,
    }
  );
};

const shareJob = async (data) => {
  return await axiosInstance.post(
    `/candidate/application/ShareAction?SharedLink=${data.SharedLink}&JobRequestID=${data.JobRequestID}&IsShared=${data.IsShared}`,
    {
      handlerEnabled,
    }
  );
};

const sendApprovalInSUmmaryStage = async (data) => {
  return await axiosInstance.post(
    `/admin/job_request/send_approvalInSummaryStage`,
    data,
    {
      handlerEnabled,
    }
  );
};
const deleteJobRequestionTemplate = async (data) => {
  return await axiosInstance.delete(
    `admin/job_requisition_template/${data.id}`,
    {
      handlerEnabled,
    }
  );
};
const deleteScreeningTemplate = async (data) => {
  return await axiosInstance.delete(
    `admin/screening_question_template/${data.id}`,
    {
      handlerEnabled,
    }
  );
};
export default {
  postJobDetails,
  manageJobRequisition,
  manageJobRequisitionSetup,
  manageScreeningQuestions,
  getLibraryQuestions,
  requestSummaryDetails,
  requestPublishJobPost,
  requestJobRequisitionCopyAllTemplate,
  requestJobRequisitionCopySingleTemplate,
  requestAdvertisementPreview,
  requestPublishAdvertisement,
  requestScreeningCopyAllTemplate,
  requestScreeningCopySingleTemplate,
  validateManageScreeningQuestions,
  manageJobRequisitionValidation,
  requestHasApproval,
  requestAddNewJobTitle,
  requestDisableScreening,
  getApprovalStageRequest,
  sendApprovalInSUmmaryStage,
  postDrJob,
  deleteJobRequestionTemplate,
  deleteScreeningTemplate,
  shareJob,
};
