import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/vacationBalances";
import API from "../../network/apis/vacationBalances";
import { dispatchError, showSnackbar, getURLParams } from "utils/shared";
import * as ACTION from "../actions/vacationBalances";

export function* getVacationBalancesRequest_({ payload }) {
  try {
    const response = yield call(API.getVacationBalancesRequest, payload);
    yield put(ACTION.getVacationBalancesRecieve(response.data));
  } catch (err) {
    console.log(err);
    dispatchError(err.response?.data);
  }
}

export function* getSalaryGradeRequest({ payload }) {
  try {
    const response = yield call(API.getSalaryGradeRequest, payload);
    yield put(ACTION.getSalaryGradeRecieve(response.data));
  } catch (err) {
    console.log(err);
    dispatchError(err.response?.data);
  }
}
export function* addVacationBalanceRequest_({ payload }) {
  try {
    yield call(API.addVacationBalanceRequest, payload);
    showSnackbar("savedSuccessfuly");
    setTimeout(() => (window.location.href = "/vacationBalances?page=1"), 100);
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* editVacationBalanceRequest_({ payload }) {
  try {
    const response = yield call(API.editVacationBalanceRequest, payload);
    showSnackbar("savedSuccessfuly");
    setTimeout(
      () =>
        (window.location.href = `/vacationBalances?page=${
          payload.page ? payload.page : 1
        }`),
      100
    );
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* getVacationBalanceRequest_({ payload }) {
  try {
    const response = yield call(API.getVacationBalanceRequest, payload);
    yield put(ACTION.getVacationBalanceRecieve(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* deleteVacationBalanceRequest_({ payload }) {
  try {
    yield call(API.deleteVacationBalanceRequest, payload);
    yield put(ACTION.deleteVacationBalanceRecieve(true));
    showSnackbar("done");
    // call get list rejection reasons "sagas"
    yield call(getVacationBalancesRequest_, {
      payload: { pageNumber: getURLParams("page"), page_size: 10 },
    });
  } catch (err) {
    dispatchError(err?.response?.data);
  }
}

export function* VacationBalancesSagasWatch() {
  yield takeEvery(
    types.ADD_VACATION_BALANCE_REQUEST,
    addVacationBalanceRequest_
  );
  yield takeEvery(
    types.DELETE_VACATION_BALANCE_REQUEST,
    deleteVacationBalanceRequest_
  );
  yield takeEvery(
    types.EDIT_VACATION_BALANCE_REQUEST,
    editVacationBalanceRequest_
  );
  yield takeEvery(
    types.GET_VACATION_BALANCES_REQUEST,
    getVacationBalancesRequest_
  );
  yield takeEvery(
    types.GET_VACATION_BALANCE_REQUEST,
    getVacationBalanceRequest_
  );
  yield takeEvery(types.GET_SALARY_GRADE_REQUEST, getSalaryGradeRequest);
}
