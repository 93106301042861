import { call, put } from "redux-saga/effects";
import API from "../../network/apis/digitalDocuments";
import * as ACTION from "../actions/digitalDocuments";
import history from "../../routes/History";
import {
  dispatchError,
  showSnackbar,
  pushToUrlNewParam,
  getURLParams,
} from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/digitalDocuments";
import {
  moveCandidatesToAnotherStageReceive,
  requestGetPipelineApplications,
  requestPipelineStages,
} from "store/actions/manageCandidates";
import History from "../../routes/History";
import store from "store";

export function* getAllDigitalDocuments(action) {
  try {
    const response = yield call(API.getAllDigitalDocuments, action.payload);
    yield put(ACTION.receiveAllDigitalDocuments(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getDigitalDocumentsPlaceholders() {
  try {
    const response = yield call(API.getAllDigitalDocumentsPlaceholders);
    yield put(ACTION.getDigitalDocumentPlaceholdersReceive(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* saveDigitalDocument({ payload }) {
  try {
    yield call(API.saveDigitalDocument, payload);
    // yield put(ACTION.getDigitalDocumentRequest());
    showSnackbar("done");
    history.push("/digitalDocuments");
  } catch (error) {
    dispatchError(error.response.data);
  }
}
export function* getDigitalDocument(action) {
  try {
    const response = yield call(API.getDigitalDocument, action.payload);
    yield put(ACTION.receiveDigitalDocumentRequest(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* requestDeleteDigitalDocument({ payload }) {
  try {
    const response = yield call(API.deteleDigitalDocument, payload);
    yield put(ACTION.receiveDeleteDigitalDocument(response.data));
    yield put(
      ACTION
        .requestAllDigitalDocuments
        // jobsLength === 1 && activePage > 1 ? activePage - 1 : activePage
        ()
    );
    // pushToUrlNewParam(
    //   jobsLength === 1 && activePage > 1 ? activePage - 1 : activePage
    // );
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getSpecificDigitalDocumentRequest(action) {
  try {
    const response = yield call(
      API.getSpecificDigitalDocumentRequest,
      action.payload
    );
    yield put(ACTION.getSpecificDigitalDocumentReceive(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* sendDigitalDocumentRequest({ payload }) {
  try {
    yield put(moveCandidatesToAnotherStageReceive(null));
    const response = yield call(API.sendDigitalDocumentRequest, payload);
    yield put(ACTION.receiveSendDigitalDocument(response.data));

    yield put(moveCandidatesToAnotherStageReceive(true));
    showSnackbar("done");

    if (History.location.pathname !== "/applicantProfile") {
      const stage_id = localStorage.getItem("active_stage_id");
      store.dispatch(
        requestPipelineStages(
          History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id")
        )
      );
      store.dispatch(
        requestGetPipelineApplications({
          job_request_id:
            History.location.pathname.split("/")[2] ||
            getURLParams("job_request_id"),
          params: {
            page_number: Number(History.location.search.split("=")[1]) || 1,
            stage_id: stage_id,
          },
        })
      );
    }
    // const stage_id = localStorage.getItem("active_stage_id");
    // yield put(
    //   requestPipelineStages(
    //     History.location.pathname.split("/")[2] ||
    //       getURLParams("job_request_id")
    //   )
    // );

    // yield put(
    //   requestGetPipelineApplications({
    //     job_request_id:
    //       History.location.pathname.split("/")[2] ||
    //       getURLParams("job_request_id"),
    //     params: {
    //       page_number: Number(History.location.search.split("=")[1]) || 1,
    //       stage_id: stage_id,
    //     },
    //   })
    // );
  } catch (error) {
    console.log(error);
    dispatchError(error.response?.data);
  }
}

export function* DigitalDocumentsSagasWatch() {
  yield takeLatest(
    type.GET_ALL_DIGITAL_DOCUMENTS_REQUEST,
    getAllDigitalDocuments
  );
  yield takeLatest(
    type.GET_DIGITAL_DOCUMENTS_PLACEHOLDERS_REQUEST,
    getDigitalDocumentsPlaceholders
  );
  yield takeLatest(type.SAVE_DIGITAL_DOCUMENT_REQUEST, saveDigitalDocument);

  yield takeLatest(type.GET_DIGITAL_DOCUMENT_REQUEST, getDigitalDocument);

  yield takeLatest(
    type.DELETE_DIGITAL_DOCUMENT_REQUEST,
    requestDeleteDigitalDocument
  );
  yield takeLatest(
    type.GET_SPECIFIC_DIGITAL_DOCUMENT_REQUEST,
    getSpecificDigitalDocumentRequest
  );

  yield takeLatest(
    type.SEND_DIGITAL_DOCUMENT_REQUEST,
    sendDigitalDocumentRequest
  );
}
