import * as types from "../types/dashboard";

export const dsahboardCardsRequest = (payload) => ({
  type: types.DASHBOARD_CARDS_DATA_REQUEST,
  payload,
});

export const dsahboardCardsReceive = (payload) => ({
  type: types.DASHBOARD_CARDS_DATA_RECEIVE,
  payload,
});

export const reportDataRequest = (payload) => ({
  type: types.REPORT_DATA_REQUEST,
  payload,
});
export const exportExcelRequest = payload => ({
  type: types.EXPORT_EXCEL_REQUEST,
  payload
});

export const exportExcelRecieve = payload => ({
  type: types.EXPORT_EXCEL_RECIEVE,
  payload
});

export const exportCvsRequest = payload => ({
  type: types.EXPORT_CVS_REQUEST,
  payload
});

export const exportCvsRecieve = payload => ({
  type: types.EXPORT_CVS_RECIEVE,
  payload
});

export const reportDataRecieve = (payload) => ({
  type: types.REPORT_DATA_RECIEVE,
  payload,
});

export const joinersReportRequest = (payload) => ({
  type: types.JOINERS_REPORT_REQUEST,
  payload,
});


export const joinersReportRecieve = (payload) => ({
  type: types.JOINERS_REPORT_RECIEVE,
  payload,
});

export const jobStagesRequest = (payload) => ({
  type: types.JOB_STAGES_REPORTS_REQUEST,
  payload,
});


export const jobStagesRecieve = (payload) => ({
  type: types.JOB_STAGES_REPORTS_RECIEVE,
  payload,
});

export const agentJobReportRequests = (payload) => ({
  type: types.AGENT_JOB_REPORT_REQUEST,
  payload,
});

export const agentJobReportRecieve = (payload) => ({
  type: types.AGENT_JOB_REPORT_RECIEVE,
  payload,
});

export const monthlyHiringReportRequest = (payload) => ({
  type: types.MONTHLY_HIRING_REPORT_REQUEST,
  payload,
});

export const monthlyHiringRecieve = (payload) => ({
  type: types.MONTHLY_HIRING_REPORT_RECIEVE,
  payload,
});

export const downloadPDFFile = (data) => {
  return {
    type: types.DOWNLOAD_PDF_FILE,
    payload: data,
  };
};
export const verifyEmailRequest = (data) => {
  return {
    type: types.VERIFY_EMAIL_REQUEST,
    payload: data,
  };
};
export const verifyEmailRecieve = (data) => {
  return {
    type: types.VERIFY_EMAIL_RECIEVE,
    payload: data,
  };
};
