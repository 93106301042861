import { call, put } from "redux-saga/effects";
import API from "../../network/apis/apis";
import * as ACTION from "../actions";
import history from "../../routes/History";
import { dispatchError, showSnackbar } from "../../utils/shared";
import { takeLatest } from "redux-saga/effects";
import * as type from "../types/CompanySetup";
import { colorsWithPlan } from "utils/CompanyPortalSetup";
import cssVariables from "scss/_export-variables.scss";
var hexToRgb = require("hex-rgb");


export function* getCompanyDetails() {
  try {
    const response = yield call(API.getCompanyDetails);
    const { data } = response;
    yield localStorage.setItem(
      "company_image_url",
      `${data.logo_url}?time=${new Date().getTime()}`
    );
    yield localStorage.setItem('company_stamp_url', data.logo_url)
    yield put(ACTION.receiveCompanyDetails(response.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* updateCompanyDetails(action) {
  try {
    const response = yield call(API.editCompany, action.payload);
    yield put(ACTION.receiveEditCompanyDetails(response.data));
    showSnackbar("savedSuccessfuly");
    history.push("/companySetup");
    localStorage.setItem("is_company_profile_complete", true);
    // localStorage.setItem("company_image_url", action.payload.logo);
  } catch (err) {
    err.response?.data&&dispatchError(err.response?.data);
  }
}

export function* addEditBranches(action) {
  try {
    const response = yield call(API.addUpdateBranches, action.payload.data);
    yield put(ACTION.receiveAddEditBranches(response.data));
    showSnackbar("savedSuccessfuly");
    action.payload.type !== "saveNew" && history.push("/companySetup");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* getBranches() {
  try {
    const response = yield call(API.getBranches);
    yield put(ACTION.receiveGetBranches(response.data.data));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}

export function* deleteBranch(action) {
  try {
    const response = yield call(API.deleteBranch, action.payload);
    yield put(ACTION.receiveDeleteBranch(response.data));
    showSnackbar("done");
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* companyPortalSetupRecieve(payload) {
  try {
    const response = yield call(API.getCompanyPortalSetupRequest);
    yield put(ACTION.companyPortalSetupRecieve({}));
  } catch (err) {
    dispatchError(err.response?.data);
  }
}
export function* getCompanyPortalSetup() {
  try {
    const response = yield call(API.getCompanyPortalSetupRequest);
    const brandedSite = colorsWithPlan(response.data.data);
    const secondaryColorTransparent = hexToRgb(
      brandedSite.secondary_color, {
      format: "array",
    });
    document.documentElement.style.setProperty(
      "--primary-color",
      brandedSite.primary_color
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      brandedSite.secondary_color
    );
    document.documentElement.style.setProperty(
      "--transparent-color",
      brandedSite.transparent_color
    );
    document.documentElement.style.setProperty(
      "--secondary-color-transparent",
      `rgba(${secondaryColorTransparent[0]},${secondaryColorTransparent[1]}, ${secondaryColorTransparent[2]}, .3)`
    );
    cssVariables.primaryColor = brandedSite.primary_color;
    cssVariables.secondaryColor = brandedSite.secondary_color;
    cssVariables.transparentColor = brandedSite.transparent_color;
    yield call(companyPortalSetupRecieve);
  } catch (err) {
    dispatchError(err.response);
  }
}

export function* CompanySetupSagasWatch() {
  yield takeLatest(type.GET_COMPANY_DETAILS_REQUEST, getCompanyDetails);
  yield takeLatest(type.EDIT_COMPANY_DETAILS_REQUEST, updateCompanyDetails);
  yield takeLatest(type.ADD_UPDATE_BRANCH_REQUEST, addEditBranches);
  yield takeLatest(type.DELETE_BRANCH_REQUEST, deleteBranch);
  yield takeLatest(type.GET_BRANCHES_REQUEST, getBranches);
  yield takeLatest(
    type.GET_COMPANY_PORTAL_SETUP_REQUEST,
    getCompanyPortalSetup
  );
}
