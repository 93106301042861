import * as types from "../types/vacationBalances";

const INITIAL_STATE = {
  isVacationDeleted: false,
  paging: {},
  data: [],
  currentVacation: null,
  gradeList: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.ADD_VACATION_BALANCE_RECIEVE:
      return { ...state, agent: action.payload };
    case types.GET_VACATION_BALANCES_RECIEVE:
      return {
        ...state,
        data: action.payload?.data,
        paging: action.payload?.paging,
      };
    case types.GET_VACATION_BALANCE_RECIEVE:
      return { ...state, currentVacation: action.payload };
    case types.DELETE_VACATION_BALANCE_RECIEVE:
      return { ...state, isVacationDeleted: action.payload };
    case types.GET_SALARY_GRADE_RECIEVE:
      return { ...state, gradeList: action.payload };
    default:
      return state;
  }
};
