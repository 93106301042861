import { axiosInstance } from "./config";

let handlerEnabled = true;

const addVacationBalanceRequest = async (data) => {
  return await axiosInstance.post(`/admin/nationality_vacation_balance`, data, {
    handlerEnabled,
  });
};
const editVacationBalanceRequest = async (data) => {
  return await axiosInstance.put(
    `/admin/nationality_vacation_balance/${data.id}`,
    data,
    { handlerEnabled }
  );
};
const deleteVacationBalanceRequest = async (data) => {
  return await axiosInstance.delete(
    `/admin/nationality_vacation_balance/${data}`,

    { handlerEnabled }
  );
};
const getVacationBalancesRequest = async ({ page_size, pageNumber }) => {
  return await axiosInstance.get(
    `/admin/nationality_vacation_balance?page_size=${page_size}&page_number=${pageNumber}`,
    { handlerEnabled }
  );
};
const getSalaryGradeRequest = async () => {
  return await axiosInstance.get(`/admin/salary_grade/SlaryGradeDDL`, {
    handlerEnabled,
  });
};
const getVacationBalanceRequest = async ({ id }) => {
  return await axiosInstance.get(`/admin/nationality_vacation_balance/${id}`, {
    handlerEnabled,
  });
};
export default {
  getVacationBalanceRequest,
  getVacationBalancesRequest,
  deleteVacationBalanceRequest,
  editVacationBalanceRequest,
  addVacationBalanceRequest,
  getSalaryGradeRequest,
};
