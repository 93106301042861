import History from "routes/History";

const { axiosInstance } = require("./config");
let handlerEnabled = true;

const getAllDigitalDocuments = async (pageNumber) => {
  let params = {
    page_number: pageNumber ? pageNumber : 1,
    page_size: 10,
  };

  return await axiosInstance.get(
    `/admin/Digital_Document/list/Digtial_Document`,
    {
      params,
      handlerEnabled,
    }
  );
};

const getAllDigitalDocumentsPlaceholders = async () =>
  await axiosInstance.get(`/admin/Digital_Document/placeholders`);

const saveDigitalDocument = async (data) =>
  await axiosInstance.post(`/admin/Digital_Document/Add_Edit`, data);

const getDigitalDocument = async (id) => {
  return await axiosInstance.get(
    `/admin/Digital_Document/DigitalDocument/${id}`,
    { handlerEnabled }
  );
};

const deteleDigitalDocument = async (id) =>
  await axiosInstance.delete(`/admin/Digital_Document/${id}`);

const getSpecificDigitalDocumentRequest = async (payload) => {
  return await axiosInstance.get(
    `/admin/Digital_Document/applications/${payload.application_id}/CandidateApplication/${payload.CandidateAppID}/Document/${payload.DocumentID}/GetDigitalDocumentData`
  );
};
const sendDigitalDocumentRequest = async (data) => {
  let applicationId=History.location.pathname.split("/")[2]
  return await axiosInstance.post(
    `/admin/Digital_Document/application/${applicationId}/Candidate/${data.CandidateID}/candidateApp/${data.CandidateApplicationID}/Document/${data.DocumentID}/Stage/${data.stage}/SendDigitalDocumentEmail`,
    data
  );
};

export default {
  getAllDigitalDocuments,
  getAllDigitalDocumentsPlaceholders,
  saveDigitalDocument,
  getDigitalDocument,
  deteleDigitalDocument,
  getSpecificDigitalDocumentRequest,
  sendDigitalDocumentRequest,
};
