import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types/EditEmail";
import API from "../../network/apis/EditEmail";
import { dispatchError } from "utils/shared";
import * as ACTION from "../actions/editEmail";
import { changeText, replaceText } from "components/EditEmail/EditEmailUtils";
import moment from "moment";

export function* getEmailTemplate({ payload }) {
  try {
    const response = yield call(API.getEmailTemplateRequest, payload);
    const templateForInterview = payload.template_type === "interview";

    const templateForCancellation =
      payload.template_type === "cancel-interview";

    const templateFordigitalDoc = payload.template_type === "digital-document";
    if (templateForInterview) {
      const body = yield replaceText(response.data.data.body, {
        INTERVIEW_DATE: payload.rescheduleDate
          ? moment(payload.rescheduleDate).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY"),
        INTERVIEW_TIME: payload.rescheduleTime
          ? moment(payload.rescheduleTime, "HH:mm")
              .tz(response.data.data["time-zone"])
              .format("hh:mm A")
          : moment(new Date())
              .tz(response.data.data["time-zone"])
              .format("hh:mm A"),
        INTERVIEWER_NAME:
          payload.isScheduleType && payload.interviewerName
            ? payload.interviewerName
            : "[interviewer-name]",
        INTERVIEW_LOCATION: payload.location
          ? payload.location
          : "[INTERVIEW_LOCATION]",
      });
      yield put(
        ACTION.getTemplateEmailRecieve({
          ...response.data.data,
          ...(payload.source && { source: "api" }),
          body,
        })
      );
    } else if (templateForCancellation) {
      const body = yield replaceText(response.data.data.body, {
        CANCEL_REASON: payload.cancel_reason
          ? payload.cancel_reason
          : "[CANCEL_REASON]",
      });
      yield put(
        ACTION.getTemplateEmailRecieve({
          ...response.data.data,
          ...(payload.source && { source: "api" }),
          body,
        })
      );
    } 
    else if(templateFordigitalDoc){
      const body = yield changeText(response.data.data.body, 
        payload.documentNameEN,
        payload.documentNameEN,

      );
      yield put(
        ACTION.getTemplateEmailRecieve({
          ...response.data.data,
          ...(payload.source && { source: "api" }),
          body,
        })
      );
    }
    
    
    
    else {
      yield put(
        ACTION.getTemplateEmailRecieve({
          ...response.data.data,
          ...(payload.source && { source: "api" }),
        })
      );
    }

    if(templateFordigitalDoc){
      const body = yield replaceText(response.data.data.body, {
        DIGITAL_DOCUMENT: payload.documentNameEN
          ? payload.documentNameEN
          : "[DIGITAL_DOCUMENT]",
      });
      yield put(
        ACTION.getTemplateEmailRecieve({
          ...response.data.data,
          ...(payload.source && { source: "api" }),
          body,
        })
      );
    }
    if (templateForCancellation) {
      const body = yield replaceText(response.data.data.body, {
        CANCEL_REASON: payload.cancel_reason
          ? payload.cancel_reason
          : "[CANCEL_REASON]",
      });

      yield put(
        ACTION.getTemplateEmailRecieve({
          ...response.data.data,
          body,
        })
      );
    }


    // } else {
    //   yield put(
    //     ACTION.getTemplateEmailRecieve({
    //       ...response.data.data,
    //       ...(payload.source && { source: "api" }),
    //     })
    //   );
    // }
  } catch (err) {
    // dispatchError(err.response?.data);
  }
}
export function* EditEmailSagasWatch() {
  yield takeEvery(types.GET_TEMPLATE_EMAIL_REQUEST, getEmailTemplate);
}
