import { axiosInstance } from "./config";

let handlerEnabled = true;

const requests = async (params) => {
  return await axiosInstance.get(`/admin/approval_request/pending`, {
    params,
    handlerEnabled,
  });
};
const oldRequests = async (params) => {
  return await axiosInstance.get(`/admin/approval_request/oldRequests`, {
    params,
    handlerEnabled,
  });
};
const approverRequests = async ({ id, comment, assign_to_agent_id }) => {
  return await axiosInstance.put(
    `/admin/approval_request/approve/${id}`,
    { comment, assign_to_agent_id },
    {
      handlerEnabled,
    }
  );
};

const reAssignRequests = async ({ id, comment, assign_to_agent_id }) => {
  return await axiosInstance.put(
    `/admin/approval_request/Reassign/${id}`,
    { comment, assign_to_agent_id },
    {
      handlerEnabled,
    }
  );
};
const candidateApprovalsRequests = async (params) => {
  return await axiosInstance.get(`/admin/candidate_approval_requests`, {
    params,
  });
};

const acceptedOffersRequest = async (params) => {
  return await axiosInstance.get(`/admin/offers/accepted`, {
    params,
  });
};
const approveCandidateApprovalsRequests = async ({ request_id, data }) => {
  return await axiosInstance.post(
    `/admin/candidate_approval_requests/extend_more_info_request/${request_id}/approve`,
    data
  );
};
const approveCandidateJoiningDateRequests = async ({ request_id, data }) => {
  return await axiosInstance.post(
    `/admin/candidate_approval_requests/joining_date/${request_id}/approve`,
    data
  );
};

const rejectCandidateApprovalsRequests = async (request_id) => {
  return await axiosInstance.post(
    `/admin/candidate_approval_requests/extend_more_info_request/${request_id}/reject`
  );
};

const disApproveRequests = async ({ id, data }) => {
  return await axiosInstance.put(`/admin/approval_request/reject/${id}`, data, {
    handlerEnabled,
  });
};

const uploadSignature = async (data) =>
  await axiosInstance.post(`admin/agent/signature`, data);

const getOfferTemplateOldRequests = async ({ offer_id, approval_request_id }) =>
  await axiosInstance.get(
    `/admin/offers/oldRequests/${offer_id}/${approval_request_id}`
  );

const getOfferTemplatePendingRequests = async ({
  offer_id,
  approval_request_id,
}) =>
  await axiosInstance.get(
    `/admin/offers/pending/${offer_id}/${approval_request_id}`
  );

const getCandidateOfferTemplateRequest = async (id) =>
  await axiosInstance.get(`/admin/offers/${id}`);

const sendOfferTemplate = async ({ data, offer_id, approval_request_id }) =>
  await axiosInstance.post(
    `/admin/offers/${offer_id}/approval_requests/${approval_request_id}/sign_and_approve`,
    data
  );

const saveUploadedAprovalOffer = async (data) =>
  await axiosInstance.post(`/admin/offers/uploadOffer_AttachFile`, data);

const getActiveUnifunicSMS = async (data) => {
  return await axiosInstance.get(
    "admin/companyIntegration/getCompanyUnifonicIntegrationsActivation"
  );
};
/* CONTRACT  */
const getContractTemplateOldRequests = async ({
  approval_request_id,
  contract_id,
}) =>
  await axiosInstance.get(
    `/admin/contracts/oldRequests/${contract_id}/${approval_request_id}`
  );

const getContractTemplatePendingRequests = async ({
  approval_request_id,
  contract_id,
}) =>
  await axiosInstance.get(
    `/admin/contracts/pending/${contract_id}/${approval_request_id}`
  );

const sendContractTemplate = async ({ data }) =>
  await axiosInstance.post(
    `/admin/contracts/approval_requests/sign_and_approve`,
    data
  );
const saveUploadedAprovalContract = async (data) =>
  await axiosInstance.post(`/admin/contracts/uploadContract_AttachFile`, data);

export default {
  saveUploadedAprovalOffer,
  requests,
  approverRequests,
  reAssignRequests,
  disApproveRequests,
  uploadSignature,
  getOfferTemplatePendingRequests,
  getOfferTemplateOldRequests,
  sendOfferTemplate,
  candidateApprovalsRequests,
  approveCandidateApprovalsRequests,
  rejectCandidateApprovalsRequests,
  getActiveUnifunicSMS,
  oldRequests,
  saveUploadedAprovalContract,
  sendContractTemplate,
  getContractTemplateOldRequests,
  getContractTemplatePendingRequests,
  approveCandidateJoiningDateRequests,
  getCandidateOfferTemplateRequest,
  acceptedOffersRequest,
};
