import { axiosInstance } from "./config";

let handlerEnabled = true;

const myInterviewsRequest = async (parameters) => {
  let params = {
    page_number: parameters.pageNumber ? parameters.pageNumber: 1,
    page_size: 10,
    ...parameters.filters,
  };
  return await axiosInstance.get(`/admin/agent_interview/get`, {
    params,
    handlerEnabled,
  });
};
const myPrevInterviewsRequest = async (parameters) => {
  let params = {
    page_number: parameters.pageNumber ? parameters.pageNumber : 1,
    page_size: 10,
    ...parameters.filters,
  };
  return await axiosInstance.get(
    `/admin/agent_interview/getPreviousInterview`,
    {
      params,
      handlerEnabled,
    }
  );
};
const interviewDetailsRequest = async ({ interview_id, candidate_id ,ApplicationID}) => {
  return await axiosInstance.get(
    `/admin/candidate_interview/${interview_id}/candidate/${candidate_id}/ApplicationID/${ApplicationID}`,
    {
      handlerEnabled,
    }
  );
};
export default {
  myInterviewsRequest,
  interviewDetailsRequest,
  myPrevInterviewsRequest,
};
