import * as types from "../types/reminders";

const INITIAL_STATE = {
  NewComersAnnouncementReminder: 0,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_AGENT_REMINDERS_RECEIVE:
      console.log(action.payload);
      return {
        ...state,
        NewComersAnnouncementReminder: action.payload,
      };
    case types.SEND_AGENT_REMINDERS_RECEIVE:
      return {
        ...state,
        NewComersAnnouncementReminder: action.payload,
      };
    default:
      return state;
  }
};
