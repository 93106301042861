import * as types from "../types/vacationBalances";
export const editVacationBalanceRequest = (payload) => {
  return {
    type: types.EDIT_VACATION_BALANCE_REQUEST,
    payload,
  };
};
export const editVacationBalanceRecieve = (payload) => {
  return {
    type: types.EDIT_VACATION_BALANCE_RECIEVE,
    payload,
  };
};
export const getVacationBalancesRequest = (payload) => {
  return {
    type: types.GET_VACATION_BALANCES_REQUEST,
    payload,
  };
};
export const getVacationBalancesRecieve = (payload) => {
  return {
    type: types.GET_VACATION_BALANCES_RECIEVE,
    payload,
  };
};
export const getSalaryGradeRequest = (payload) => {
  return {
    type: types.GET_SALARY_GRADE_REQUEST,
    payload,
  };
};
export const getSalaryGradeRecieve = (payload) => {
  return {
    type: types.GET_SALARY_GRADE_RECIEVE,
    payload,
  };
};
export const getVacationBalanceRequest = (payload) => {
  return {
    type: types.GET_VACATION_BALANCE_REQUEST,
    payload,
  };
};
export const getVacationBalanceRecieve = (payload) => {
  return {
    type: types.GET_VACATION_BALANCE_RECIEVE,
    payload,
  };
};
export const addVacationBalanceRequest = (payload) => {
  return {
    type: types.ADD_VACATION_BALANCE_REQUEST,
    payload,
  };
};
export const addVacationBalanceRecieve = (payload) => {
  return {
    type: types.ADD_VACATION_BALANCE_RECIEVE,
    payload,
  };
};

export const deleteVacationBalanceRequest = (payload) => {
  return {
    type: types.DELETE_VACATION_BALANCE_REQUEST,
    payload,
  };
};
export const deleteVacationBalanceRecieve = (payload) => {
  return {
    type: types.DELETE_VACATION_BALANCE_RECIEVE,
    payload,
  };
};
