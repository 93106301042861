import * as types from "../types/competenceSettings";

const INITIAL_STATE = {levels:null,categories:null,categoriesHaveItems:null,competencies:null};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_COMPETENCE_SETTINGS_RECEIVE:
      return { ...state, levels:action.payload };
    case types.GET_COMPETENCE_SETTINGS_CATEGORIES_RECEIVE:
      return { ...state, categories:action.payload };
    case types.GET_COMPETENCE_SETTINGS_CATEGORIES_HAVE_ITEMS_RECEIVE:
      return { ...state, categoriesHaveItems:action.payload?.map(e=>({...e,items:e.items.map(item=>({...item,id:item.id+"i"}))})) };
    case types.GET_COMPETENCE_LOOKUP_RECEIVE:
      return { ...state,competencies :action.payload };
    default:
      return state;
  }
};