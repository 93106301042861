export const ADD_JOB_DETAILS_REQUEST = "ADD_JOB_DETAILS_REQUEST";
export const ADD_JOB_DETAILS_RECEIVE = "ADD_JOB_DETAILS_RECEIVE";

export const MANAGE_JOB_REQUISITION_VALIDATION_REQUEST =
  "MANAGE_JOB_REQUISITION_VALIDATION_REQUEST";
export const MANAGE_JOB_REQUISITION_VALIDATION_RECEIVE =
  "MANAGE_JOB_REQUISITION_VALIDATION_RECEIVE";
export const MANAGE_JOB_REQUISITION_REQUEST = "MANAGE_JOB_REQUISITION_REQUEST";
export const MANAGE_JOB_REQUISITION_RECEIVE = "MANAGE_JOB_REQUISITION_RECEIVE";

export const REQUEST_JOB_REQUEST_JOB_REQUESITION =
  "REQUEST_JOB_REQUEST_JOB_REQUESITION";
export const RECEIVE_JOB_REQUEST_JOB_REQUESITION =
  "RECEIVE_JOB_REQUEST_JOB_REQUESITION";

export const POST_MANAGE_SCREENING_QUESTIONS_REQUEST =
  "POST_MANAGE_SCREENING_QUESTIONS_REQUEST";
export const POST_MANAGE_SCREENING_QUESTIONS_RECEIVE =
  "POST_MANAGE_SCREENING_QUESTIONS_RECEIVE";

export const POST_MANAGE_SCREENING_QUESTIONS_VALIDATE_REQUEST =
  "POST_MANAGE_SCREENING_QUESTIONS_VALIDATE_REQUEST";
export const POST_MANAGE_SCREENING_QUESTIONS_VALIDATE_RECEIVE =
  "POST_MANAGE_SCREENING_QUESTIONS_VALIDATE_RECEIVE";

export const GET_LIBRARY_QUESTIONS_REQUEST = "GET_LIBRARY_QUESTIONS_REQUEST";
export const GET_LIBRARY_QUESTIONS_RECEIVE = "GET_LIBRARY_QUESTIONS_RECEIVE";
export const REQUEST_SUMMARY_DETAILS = "REQUEST_SUMMARY_DETAILS";
export const RECEIVE_SUMMARY_DETAILS = "RECEIVE_SUMMARY_DETAILS";
export const REQUEST_PUBLISH_JOB_POST = "REQUEST_PUBLISH_JOB_POST";
export const RECEIVE_PUBLISH_JOB_POST = "RECEIVE_PUBLISH_JOB_POST";

export const JOB_REQUISITION_COPY_ALL_TEMPLATE_REQUEST =
  "JOB_REQUISITION_COPY_ALL_TEMPLATE_REQUEST";
export const JOB_REQUISITION_COPY_ALL_TEMPLATE_RECEIVE =
  "JOB_REQUISITION_COPY_ALL_TEMPLATE_RECEIVE";
export const JOB_REQUISITION_COPY_SINGLE_TEMPLATE_REQUEST =
  "JOB_REQUISITION_COPY_SINGLE_TEMPLATE_REQUEST";
export const JOB_REQUISITION_COPY_SINGLE_TEMPLATE_RECEIVE =
  "JOB_REQUISITION_COPY_SINGLE_TEMPLATE_RECEIVE";

export const SCREENING_COPY_ALL_TEMPLATE_REQUEST =
  "SCREENING_COPY_ALL_TEMPLATE_REQUEST";
export const SCREENING_COPY_ALL_TEMPLATE_RECEIVE =
  "SCREENING_COPY_ALL_TEMPLATE_RECEIVE";
export const SCREENING_COPY_SINGLE_TEMPLATE_REQUEST =
  "SCREENING_COPY_SINGLE_TEMPLATE_REQUEST";
export const SCREENING_COPY_SINGLE_TEMPLATE_RECEIVE =
  "SCREENING_COPY_SINGLE_TEMPLATE_RECEIVE";
export const REQUEST_ADVERTISEMENT_PREVIEW = "REQUEST_ADVERTISEMENT_PREVIEW";
export const RECEIVE_ADVERTISEMENT_PREVIEW = "RECEIVE_ADVERTISEMENT_PREVIEW";
export const REQUEST_PUBLISH_ADVERTISEMENT = "REQUEST_PUBLISH_ADVERTISEMENT";
export const RECEIVE_PUBLISH_ADVERTISEMENT = "RECEIVE_PUBLISH_ADVERTISEMENT";

export const IS_JOB_REQUEST_EDIT_MODE = "IS_JOB_REQUEST_EDIT_MODE";

export const REQUEST_HAS_APPROVAL_SETUP = "REQUEST_HAS_APPROVAL_SETUP";
export const RECEIVE_HAS_APPROVAL_SETUP = "RECEIVE_HAS_APPROVAL_SETUP";

export const ADD_NEW_JOB_TITLE_REQUEST = "ADD_NEW_JOB_TITLE_REQUEST";
export const ADD_NEW_JOB_TITLE_RECEIVE = "ADD_NEW_JOB_TITLE_RECEIVE";

export const DISABLE_SCREENING_QUESTIONS_REQUEST =
  "DISABLE_SCREENING_QUESTIONS_REQUEST";
export const DISABLE_SCREENING_QUESTIONS_RECEIVE =
  "DISABLE_SCREENING_QUESTIONS_RECEIVE";

export const GET_JOB_APPROVAL_REQUEST = "GET_JOB_APPROVAL_REQUEST";
export const SEND_JOB_APPROVAL_IN_SUMMARY_REQUEST =
  "SEND_JOB_APPROVAL_IN_SUMMARY_REQUEST";
export const GET_JOB_APPROVAL_RECIEVE = "GET_JOB_APPROVAL_RECIEVE";
export const RECEIVE_DR_JOB_POST = "RECEIVE_DR_JOB_POST";
export const REQUEST_DR_JOB_POST = "REQUEST_DR_JOB_POST";


export const RECEIVE_SHARE_JOB = "RECEIVE_SHARE_JOB";
export const REQUEST_SHARE_JOB = "REQUEST_SHARE_JOB";

export const DELETE_JOB_REQUESTION_TEMPLATE = "DELETE_JOB_REQUESTION_TEMPLATE";
export const DELETE_SCREENING_TEMPLATE = "DELETE_SCREENING_TEMPLATE";
