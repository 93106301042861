export const EDIT_VACATION_BALANCE_REQUEST = 'EDIT_VACATION_BALANCE_REQUEST';
export const EDIT_VACATION_BALANCE_RECIEVE = 'EDIT_VACATION_BALANCE_RECIEVE';
export const GET_VACATION_BALANCES_REQUEST = 'GET_VACATION_BALANCES_REQUEST';
export const GET_VACATION_BALANCES_RECIEVE = 'GET_VACATION_BALANCES_RECIEVE';
export const ADD_VACATION_BALANCE_REQUEST = 'ADD_VACATION_BALANCE_REQUEST';
export const ADD_VACATION_BALANCE_RECIEVE = 'ADD_VACATION_BALANCE_RECIEVE';
export const DELETE_VACATION_BALANCE_REQUEST = 'DELETE_VACATION_BALANCE_REQUEST';
export const DELETE_VACATION_BALANCE_RECIEVE = 'DELETE_VACATION_BALANCE_RECIEVE'; 
export const GET_VACATION_BALANCE_RECIEVE = 'GET_VACATION_BALANCE_RECIEVE';
export const GET_VACATION_BALANCE_REQUEST = 'GET_VACATION_BALANCEREQUEST';

export const GET_SALARY_GRADE_REQUEST = 'GET_SALARY_GRADE_REQUEST';
export const GET_SALARY_GRADE_RECIEVE = 'GET_SALARY_GRADE_RECIEVE';
