export const dynamicTextEditorUpdates = (content, oldValue, newValue) => {
  return content.replace(oldValue, newValue);
};

// export const replaceText2 = (content, valuesToBeReplaced) => {
//   const re =
//     /\[INTERVIEW_TIME]|\[INTERVIEW_DATE]|\[MORE_INFO_EXPIRE_DATE]|\[INTERVIEWER_NAME]\[CANCEL_REASON]/gi;
//   return content.replace(re, function (matched) {
//     const key = matched.slice(1, -1);

//     return `<strong>${valuesToBeReplaced[key]}</strong>`;
//   });
// };
export const replaceText = (content, valuesToBeReplaced) => {
  const re =
    /\[INTERVIEW_TIME]|\[INTERVIEW_LOCATION]|\[DIGITAL_DOCUMENT]|\[INTERVIEW_DATE]|\[expiration-date]|\[INTERVIEWER_NAME]/gi;
  return content.replace(re, function (matched) {
    const key = matched.slice(1, -1);
    if(typeof(valuesToBeReplaced)==='object'){

      return `<strong>${valuesToBeReplaced[key]}</strong>`;
    }else{
      return `<strong>${valuesToBeReplaced}</strong>`;
      
    }
  });
};

export const changeText = (content, oldValue, newValue) => {
  if (newValue.length > 0) {
    return content.replace(/\[([^[\]]+)\]/g, `[${newValue}]`);
  } else {
    return content.replace(/\[([^[\]]+)\]/g, `[CANCEL_REASON]`);
  }
};
